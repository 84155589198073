import tw, { css, styled } from "twin.macro"

const styles = {
  default: tw`w-full grid pb-15 px-2`,
  bar: {
    default: tw` h-1.5 w-full relative bg-orange-DEFAULT`,
    ends: tw`w-1/2`,
  },
  background: {
    filled: tw`opacity-100`,
    unfilled: css`
      background-color: #ffcf9c;
      border-color: #ffcf9c;
    `,
  },
  columns: {
    2: tw`grid-cols-2`,
    3: css`
      grid-template-columns: 1fr 2fr 1fr;
    `,
    4: css`
      grid-template-columns: 1fr 2fr 2fr 1fr;
    `,
  },
  circle: {
    default: tw`h-4 w-4 bg-orange-DEFAULT flex items-center justify-center border-orange border -top-2 rounded-full absolute transform -translate-y-2/4`,
    start: css`
      left: 0;
      transform: translate(-50%, -50%);
    `,
    end: css`
      left: 100%;
      transform: translate(-50%, -50%);
    `,
    middle: css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    unfilled: tw`bg-white`,
  },
  text: {
    default: css`
      position: absolute;
      padding: 0;
      min-width: 10em;
      text-align: center;
    `,
    main: tw`text-center`,
    sm: tw`text-xxxxs md:text-xxs pt-4`,
    lg: tw`text-sm`,
  },
  transform: {
    recenter: css`
      left: 50%;
      transform: translateX(-50%);
    `,
    end: css`
      right: 0;
      text-align: right;
    `,
  },
}

export const BarContainer = styled.div`
  ${styles.default}
  ${({ colNum }) => colNum && styles.columns[colNum]}
`
export const First = styled.div`
  ${styles.bar.default}
  ${({ met }) => (met ? styles.background.filled : styles.background.unfilled)}
`
export const Middle = styled.div`
  ${styles.bar.default}
  ${({ met }) => (met ? styles.background.filled : styles.background.unfilled)}
`
export const Last = styled.div`
  ${styles.bar.default}
  ${({ met }) => (met ? styles.background.filled : styles.background.unfilled)}
`
export const Circle = styled.div`
  ${styles.circle.default}
  ${styles.circle.middle}
  ${({ position }) => (position === "middle" ? styles.circle.middle : position === "start" ? styles.circle.start : styles.circle.end)}
  ${({ met }) => (met ? styles.background.filled : styles.circle.unfilled)}
`
export const Checkmark = tw.p`text-white text-xs`
export const SMText = styled.p`
  ${styles.text.default}
  ${styles.text.sm}
  ${({ position }) => (position === "middle" ? styles.transform.recenter : position === "end" ? styles.transform.end : null)}
`
export const LGText = styled.p`
  ${styles.text.main}
  ${styles.text.lg}
`
export const HeaderContainer = tw.div`flex flex-col items-center pt-5 pb-3`
