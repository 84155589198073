import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "./Global"

const styles = {
  default: tw`inline-block select-none text-center max-w-full outline-none focus:outline-none transition-all duration-200`,
  colour: {
    orange: tw`text-white bg-orange border-orange`,
    purple: tw`text-white bg-purple-dark border-purple-dark`,
    white: tw`text-orange bg-white border-white`,
    "green-outline": tw`bg-transparent text-green border-green hover:bg-green hover:text-white`,
    "green-dark-outline": tw`bg-transparent text-green-dark border-green-dark hover:bg-green-dark hover:text-white`,
    "green-light-outline": tw`bg-transparent text-green-light border-green-light hover:bg-green-light hover:text-white`,
    "orange-outline": tw`bg-transparent text-orange border-orange hover:bg-orange hover:text-white`,
    "orange-dark-outline": tw`bg-transparent text-orange-dark border-orange-dark hover:bg-orange-dark hover:text-white`,
    "orange-light-outline": tw`bg-transparent text-orange-light border-orange-light hover:bg-orange-light hover:text-white`,
    "pink-dark-outline": tw`bg-transparent text-pink-dark border-pink-dark hover:bg-pink-dark hover:text-white`,
    "pink-light-outline": tw`bg-transparent text-pink-light border-pink-light hover:bg-pink-light hover:text-white`,
    "purple-dark-outline": tw`bg-transparent text-purple-dark border-purple-dark hover:bg-purple-dark hover:text-white`,
    "purple-light-outline": tw`bg-transparent text-purple-light border-purple-light hover:bg-purple-light hover:text-white`,
    "red-outline": tw`bg-transparent text-red border-red hover:bg-red hover:text-white`,
    "yellow-dark-outline": tw`bg-transparent text-yellow-dark border-yellow-dark hover:bg-yellow-dark hover:text-white`,
    "yellow-light-outline": tw`bg-transparent text-yellow-light border-yellow-light hover:bg-yellow-light hover:text-white`,
    link: tw`bg-transparent text-grey-darkest border-transparent hover:text-orange-dark hover:border-orange-dark`,
    "link-grey-darker": tw`bg-transparent text-grey-darker border-transparent hover:text-orange-dark hover:border-orange-dark`,
    "link-grey-dark": tw`bg-transparent text-grey-dark border-transparent hover:text-orange-dark hover:border-orange-dark`,
    "link-grey": tw`bg-transparent text-grey border-transparent hover:text-orange-dark hover:border-orange-dark`,
    "link-white": tw`bg-transparent text-white border-transparent hover:text-grey-lighter hover:border-grey-lighter`,
    "link-green": tw`bg-transparent text-green border-transparent hover:text-green hover:border-green`,
    "link-green-dark": tw`bg-transparent text-green-dark border-transparent hover:text-green-dark hover:border-green-dark`,
    "link-green-light": tw`bg-transparent text-green-light border-transparent hover:text-green-light hover:border-green-light`,
    "link-orange": tw`bg-transparent text-orange border-transparent hover:text-orange hover:border-orange`,
    "link-orange-dark": tw`bg-transparent text-orange-dark border-transparent hover:text-orange-dark hover:border-orange-dark`,
    "link-orange-light": tw`bg-transparent text-orange-light border-transparent hover:text-orange-light hover:border-orange-light`,
    "link-pink-dark": tw`bg-transparent text-pink-dark border-transparent hover:text-pink-dark hover:border-pink-dark`,
    "link-pink-light": tw`bg-transparent text-pink-light border-transparent hover:text-pink-light hover:border-pink-light`,
    "link-purple-dark": tw`bg-transparent text-purple-dark border-transparent hover:text-purple-dark hover:border-purple-dark`,
    "link-purple-light": tw`bg-transparent text-purple-light border-transparent hover:text-purple-light hover:border-purple-light`,
    "link-red": tw`bg-transparent text-red border-transparent hover:text-red hover:border-red`,
    "link-yellow-dark": tw`bg-transparent text-yellow-dark border-transparent hover:text-yellow-dark hover:border-yellow-dark`,
    "link-yellow-light": tw`bg-transparent text-yellow-light border-transparent hover:text-yellow-light hover:border-yellow-light`,
  },
  disabled: tw`pointer-events-none opacity-50`,
  full: tw`block w-full md:w-full`,
  link: {
    default: tw`flex items-center justify-center select-none rounded-none hover:opacity-75 max-w-full outline-none focus:outline-none transition-all duration-200`,
    underline: css`
      ${tw`border-b-2 font-bold text-base md:text-xl hover:opacity-100`}

      svg {
        ${tw`w-3 md:w-3.75 ml-1 md:ml-2`}
      }
    `,
  },
  size: {
    primary: tw`p-4.25 md:py-5.75 md:px-14 w-full md:w-auto text-xl border font-bold md:text-2xl rounded-6xl hover:shadow-md`,
    secondary: tw`px-0.75 py-2.75 md:px-8 w-full md:w-auto text-base border font-bold md:font-semibold rounded-6xl hover:shadow-md`,
    tertiary: tw`px-0.75 py-1.75 md:py-0.75 md:px-8 w-full md:w-auto text-xs md:text-base border font-bold md:font-semibold rounded-6xl hover:shadow-md`,
    "tertiary-auto": tw`py-1.75 md:py-0.75 w-full md:w-auto text-xs md:text-base border font-bold md:font-semibold rounded-6xl hover:shadow-md`,
    "link-large": tw`text-base md:text-xl font-bold`,
    quaternary: tw`px-0.75 py-1.75 md:py-0.75 md:px-8 w-32 md:w-auto text-xs md:text-base border font-bold md:font-semibold rounded-6xl hover:shadow-md`,
    proto: tw`p-4.25 md:py-5.75 md:px-14 w-full md:w-auto text-xl border font-bold md:text-2xl rounded-6xl hover:shadow-md lg:max-w-[385px]`,
  },
}

export const StyledButton = styled.button`
  ${styles.default}
  ${({ size }) => size && styles.size[size]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ full }) => full && styles.full}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}

  * {
    ${tw`pointer-events-none`}
  }
`

export const StyledLink = styled.button`
  ${styles.link.default}
  ${({ colour }) => (colour && styles.colour[`link-${colour}`]) || styles.colour.link}
  ${({ size }) => size && styles.size[`link-${size}`]}
  ${({ disabled }) => disabled && styles.disabled}
  ${({ underline }) => underline && styles.link.underline}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ proto }) => proto && tw`block underline text-sm font-normal mb-2 lg:mt-1 lg:mb-3.5`}
  ${({ proto }) =>
    proto &&
    css`
      text-underline-offset: 3px;
    `}
  * {
    ${tw`pointer-events-none`}
  }
`
