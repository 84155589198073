import { useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useCheckoutContext } from "./useCheckout"
import { useCart } from "./useCart"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

export const useGift = () => {
  const {
    helpers: { edgeNormaliser },
  } = useApp()
  const { addToCart, loading, removeFromCart } = useCart()
  const { checkout } = useCheckoutContext()
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const { productNormaliser } = useShopify()

  const { gifts: rawGifts } = useStaticQuery(graphql`
    query {
      gifts: allSanitySettingGift {
        edges {
          node {
            description
            price
            exclusions: _rawExclusions(resolveReferences: { maxDepth: 2 })
            product: _rawProduct(resolveReferences: { maxDepth: 2 })
          }
        }
      }
    }
  `)

  const gifts = edgeNormaliser(rawGifts)
    ?.filter(gift => gift?.product?.shopify?.shopifyRaw)
    ?.map(gift => ({
      ...gift,
      exclusions: gift?.exclusions?.map(product => productNormaliser(product)),
      price: Number(gift?.price?.toString()?.split(`.`)?.[0]) * 100,
      product: {
        ...(gift?.product || {}),
        variant: JSON.parse(gift?.product?.shopify?.shopifyRaw)?.variants?.[0],
      },
    }))
    ?.map(gift => ({
      ...gift,
      id: decodeShopifyId(gift?.product?.variant?.id, `ProductVariant`),
      secret: decodeShopifyId(gift?.product?.variant?.id, `ProductVariant`) * gift?.price * 853,
    }))

  const attainTotal = useCallback(
    (lineItems, exclusions) =>
      (lineItems
        ?.filter(({ product }) => !exclusions?.find(({ shopify }) => shopify?.shopifyHandle === product?.handle))
        ?.reduce((price, lineItem) => Number(lineItem?.variant?.priceV2?.amount) * lineItem?.quantity + price, 0) || 0) * 100,
    []
  )

  const applyGifts = useCallback(async () => {
    const lineItems = checkout?.lineItems?.filter(({ customAttributes }) => !customAttributes?.find(({ key }) => key === `_gift_item`))
    const giftItems = checkout?.lineItems?.filter(({ customAttributes }) => customAttributes?.find(({ key }) => key === `_gift_item`))

    const giftsToAdd =
      gifts?.filter(
        ({ exclusions, price, product }) =>
          attainTotal(lineItems, exclusions) > price && giftItems?.filter(({ variant }) => variant?.id === product?.variant?.id)?.length === 0
      ) || []
    const giftsToRemove = gifts?.filter(
      ({ exclusions, price, product }) =>
        attainTotal(lineItems, exclusions) < price &&
        giftItems?.filter(({ variant }) => decodeShopifyId(variant?.id, `ProductVariant`) === decodeShopifyId(product?.variant?.id, `ProductVariant`))
          ?.length > 0
    )
    const itemsToRemove =
      giftItems?.filter(({ variant }) =>
        giftsToRemove?.find(
          ({ product }) => decodeShopifyId(variant?.id, `ProductVariant`) === decodeShopifyId(product?.variant?.id, `ProductVariant`)
        )
      ) || []

    if (!loading) {
      for (const item of giftsToAdd) {
        const attributes = [
          {
            key: `_gift_item`,
            value: "true",
          },
          {
            key: `_gift_message`,
            value: item?.description?.toString(),
          },
          {
            key: `_min_price`,
            value: item?.price?.toString(),
          },
          {
            key: `_quantity_set`,
            value: "1",
          },
          {
            key: `_secret`,
            value: item?.secret?.toString(),
          },
          {
            key: `_variant_id`,
            value: item?.id?.toString(),
          },
        ]
        await addToCart(item?.product?.variant?.id, 1, attributes, false)
      }

      for (const item of itemsToRemove) {
        await removeFromCart(item?.id, item?.variant?.id)
      }
    }
  }, [addToCart, attainTotal, checkout, gifts, loading, removeFromCart])

  return {
    applyGifts,
    gifts,
  }
}
